import React, { useState, useMemo, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import initials from "initials";
import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useSelector, useDispatch } from "react-redux";
import { getLogout, setOperatingEmail } from "../../Redux-Saga/Redux/account";
import IconNotification from "../../Assets/Images/icon_notification.svg";

import Sidebar from "../../Components/Sidebar";
import { SwitchAccountModal } from "../../Components/modals";
import { getUserNotification } from "../../Services/User";
import { useCallback } from "react";

const Header = ({ toggle, onChangeToggle }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideOpen, setSideOpen] = useState(false);
  const [activeOwner, setActiveOwner] = useState();

  const [notificationList, setNotificationList] = useState([]);
  const [anchorEl, setAnchorEl] = useState();
  const [aboutAnchorEl, setAboutAnchorEl] = useState();

  const {
    user,
    isAuthenticated,
    sessionInfo: { assignUserList = [] } = {},
    token,
  } = useSelector((state) => state.account);

  const name = useMemo(() => {
    return `${user?.first_name || ""} ${user?.last_name}`.toUpperCase();
  }, [user]);

  const LinkSignUp = () => {
    onChangeToggle(false);
    navigate(`/auth/signup${window.location.search}`);
  };

  useEffect(() => {
    if (isAuthenticated && token) {
      handleLoadNotifications();
    }
    // eslint-disable-next-line
  }, [isAuthenticated, token]);

  const handleLoadNotifications = async () => {
    const result = await getUserNotification(token);
    if (result?.data?.data) {
      setNotificationList(result?.data?.data || []);
    }
  };

  const handleTerminateOk = useCallback(() => {
    dispatch(setOperatingEmail(activeOwner.email));
    setActiveOwner();
    navigate("/dashboard");
  }, [activeOwner, dispatch, navigate]);

  const handleMainDashboard = () => {
    dispatch(setOperatingEmail(""));
    setActiveOwner();
    setAnchorEl();
    navigate("/dashboard");
  };

  const owners = useMemo(() => {
    return assignUserList.filter((deputy, index) => {
      const idx = assignUserList.findIndex((it) => it.email === deputy.email);
      return idx === index;
    });
  }, [assignUserList]);

  const showMainDashboard = useMemo(() => {
    if (!user?.survey_id && owners?.length > 0) return false;
    return true;
  }, [owners, user]);

  return (
    <>
      <AppBar
        color="inherit"
        component="nav"
        sx={{ boxShadow: "none", borderBottom: "1px solid #dadada" }}
      >
        <Toolbar sx={{ py: 1.25, minHeight: "48px !important" }}>
          <Box component="div" sx={{ flexGrow: 1 }}>
            {isAuthenticated ? (
              <Link to="/dashboard" style={{ textDecoration: "none" }}>
                <Typography
                  color="primary"
                  fontSize={32}
                  fontWeight={700}
                  fontFamily="EB Garamond,serif"
                >
                  Peacefully
                </Typography>
              </Link>
            ) : (
              <Typography
                color="primary"
                fontSize={32}
                fontWeight={700}
                fontFamily="EB Garamond,serif"
                component="a"
                href="https://www.peacefully.com"
                sx={{ textDecoration: "none" }}
              >
                Peacefully
              </Typography>
            )}
          </Box>

          <IconButton
            color="inherit"
            edge="start"
            onClick={() => onChangeToggle(!toggle)}
            sx={{ color: "primary", display: { sm: "none" } }}
          >
            <MenuIcon sx={{ fontSize: 32 }} />
          </IconButton>

          {isAuthenticated ? (
            <>
              <IconButton
                className="notify"
                sx={{ mr: 2 }}
                onClick={() => setSideOpen(!sideOpen)}
              >
                <img src={IconNotification} alt="notification" />
              </IconButton>

              <Box onClick={(e) => setAnchorEl(e.currentTarget)}>
                {user?.avatar_url ? (
                  <Box
                    sx={{
                      backgroundImage: `url(${user?.avatar_url})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                    width={40}
                    height={40}
                    borderRadius={20}
                  />
                ) : (
                  <Box
                    width={40}
                    height={40}
                    borderRadius={20}
                    bgcolor="success.main"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    color="#fff"
                  >
                    {initials(name)}
                  </Box>
                )}
              </Box>

              <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                {showMainDashboard && (
                  <MenuItem
                    sx={{ minWidth: 250 }}
                    onClick={handleMainDashboard}
                  >
                    <Box display="flex" alignItems="center">
                      {user?.avatar_url ? (
                        <Box
                          sx={{
                            backgroundImage: `url(${user?.avatar_url})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                          width={50}
                          height={50}
                          borderRadius={25}
                        />
                      ) : (
                        <Box
                          width={50}
                          height={50}
                          borderRadius={25}
                          bgcolor="#004061"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          color="#fff"
                        >
                          {initials(name)}
                        </Box>
                      )}
                    </Box>

                    <Box ml={1} display="flex" flexDirection="column">
                      <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                        {user?.first_name} {user?.last_name}
                      </Typography>

                      <Typography variant="caption">{user?.email}</Typography>
                    </Box>
                  </MenuItem>
                )}

                {showMainDashboard && <Divider sx={{ opacity: 1 }} />}

                <MenuItem>
                  <Typography sx={{ fontSize: 10 }}>
                    Assign as deputy dashboards
                  </Typography>
                </MenuItem>

                {owners.map((owner) => (
                  <MenuItem
                    onClick={() => {
                      setActiveOwner(owner);
                      setAnchorEl();
                    }}
                    sx={{ minWidth: 250 }}
                    key={owner.email}
                  >
                    <Box display="flex" alignItems="center">
                      <Box
                        width={50}
                        height={50}
                        borderRadius={25}
                        bgcolor="#004061"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        color="#fff"
                      >
                        {initials(`${owner?.first_name} ${owner?.last_name}`)}
                      </Box>

                      <Box ml={1} display="flex" flexDirection="column">
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold" }}
                        >
                          {owner?.first_name} {owner?.last_name}
                        </Typography>
                        <Typography variant="caption">Switch</Typography>
                      </Box>
                    </Box>
                  </MenuItem>
                ))}

                <Divider sx={{ opacity: 1 }} />
                <MenuItem>
                  <Box display="flex" justifyContent="flex-end" width="100%">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => dispatch(getLogout())}
                    >
                      Sign Out
                    </Button>
                  </Box>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              <Stack direction="row" alignItems="center" spacing={4}>
                <Box display="flex" alignItems="center" onMouseOver={(e) => setAboutAnchorEl(e.currentTarget)}>
                  <Typography
                    color="primary"
                    fontWeight={500}
                    className="nav-link"
                    sx={{ mr: 0.2 }}
                  >
                    About
                  </Typography>
                  
                  <ArrowDropDownIcon sx={{ color: '#22427D' }} />
                </Box>
                
                <Popover
                  open={!!aboutAnchorEl}
                  anchorEl={aboutAnchorEl}
                  elevation={0}
                  onClose={() => setAboutAnchorEl(null)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                  PaperProps={{ sx: { mt: 2.75, borderRadius: 0 }}}
                >
                  <MenuItem
                    sx={{ px: 4, py: 1.5, fontSize: 13, '&:hover': { backgroundColor: '#3f444b', color: '#fff' } }}
                    component="a"
                    href="https://peacefully.com/employers"
                    target="_blank"
                  >
                    Employer
                  </MenuItem>

                  <MenuItem
                    sx={{ px: 4, py: 1.5, fontSize: 13, '&:hover': { backgroundColor: '#3f444b', color: '#fff' } }}
                    component="a"
                    href="https://peacefully.com/our-story/"
                    target="_blank"
                  >
                    Our Story
                  </MenuItem>

                  <MenuItem
                    sx={{ px: 4, py: 1.5, fontSize: 13, '&:hover': { backgroundColor: '#3f444b', color: '#fff' } }}
                    component="a"
                    href="https://peacefully.com/leadership/"
                    target="_blank"
                  >
                    Our Team
                  </MenuItem>

                  <MenuItem
                    sx={{ px: 4, py: 1.5, fontSize: 13, '&:hover': { backgroundColor: '#3f444b', color: '#fff' } }}
                    component="a"
                    href="https://peacefully.com/faq/"
                    target="_blank"
                  >
                    FAQ
                  </MenuItem>
                </Popover>

                <Typography
                  color="primary"
                  fontWeight={500}
                  className="nav-link"
                  href="https://peacefully.com/resources"
                  component="a"
                >
                  Articles
                </Typography>

                <Typography
                  color="primary"
                  fontWeight={500}
                  component="a"
                  className="nav-link"
                  href="https://peacefully.com/contact-us"
                >
                  Contact Us
                </Typography>

                <Typography color="primary" fontWeight={500}>
                  <Link
                    className="nav-link"
                    to={`/auth/login${window.location.search}`}
                    onClick={() => onChangeToggle(false)}
                  >
                    Login
                  </Link>
                </Typography>

                <Button
                  variant="outlined"
                  size="large"
                  onClick={LinkSignUp}
                  sx={{ px: 5, maxHeight: 48 }}
                >
                  Get Started
                </Button>
              </Stack>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {!isAuthenticated && (
        <Drawer
          variant="temporary"
          open={toggle}
          onClose={() => onChangeToggle(false)}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
          }}
        >
          <Stack direction="column" alignItems="center" spacing={4} py={5}>
            <Typography
              color="primary"
              fontWeight={500}
              component="a"
              href="https://peacefully.com/employers"
              className="nav-link"
            >
              Employers
            </Typography>

            <Typography
              color="primary"
              fontWeight={500}
              component="a"
              className="nav-link"
              href="https://guide.peacefully.com"
            >
              Articles
            </Typography>

            <Typography
              color="primary"
              fontWeight={500}
              component="a"
              className="nav-link"
              href="https://peacefully.com/contact-us"
            >
              Contact Us
            </Typography>

            <Typography color="primary" fontWeight={500}>
              <Link
                className="nav-link"
                to={`/auth/login`}
                onClick={() => onChangeToggle(false)}
              >
                Login
              </Link>
            </Typography>

            <Button
              variant="outlined"
              size="large"
              onClick={LinkSignUp}
              sx={{ px: 5 }}
            >
              Get Started
            </Button>
          </Stack>
        </Drawer>
      )}

      <Sidebar show={sideOpen} onClose={() => setSideOpen(false)}>
        <Box py={1} px={0.5}>
          <Box mb={1}>
            <Typography color="grey.600" variant="h6">
              Notifications
            </Typography>
          </Box>

          {notificationList?.length > 0 ? (
            <></>
          ) : (
            <Typography variant="body1" color="grey.700">
              You don’t have any new notifications.
            </Typography>
          )}
        </Box>
      </Sidebar>

      <SwitchAccountModal
        open={!!activeOwner}
        onClose={() => setActiveOwner()}
        onOk={handleTerminateOk}
      />
    </>
  );
};

export default Header;
