import React from "react";
import { useSelector } from "react-redux";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PLAN_NAME_MAP } from '../../../Assets/Consts/plan';

const ProfilePlan = () => {
  const { sessionInfo = {} } = useSelector((state) => state.account);
  const { currentUser = {} } = sessionInfo;
  const { planning_subscription, post_death_subscription, products } = currentUser;

  const navigate = useNavigate();

  if (!sessionInfo) return null;

  return (
    <Box py={3}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#000" }}>
          Plan Details
        </Typography>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableCell sx={{ fontWeight: "bold" }}>
              Plan/Product Name
            </TableCell>

            <TableCell sx={{ fontWeight: "bold" }}>
              Payment Type
            </TableCell>

            <TableCell sx={{ fontWeight: "bold" }}>
              Price
            </TableCell>
          </TableHead>
          
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{PLAN_NAME_MAP[product.name]}</TableCell>
                <TableCell>One Time</TableCell>
                <TableCell>${(Number(product.price)).toFixed(2)}</TableCell>
              </TableRow>
            ))}
            
            {!!planning_subscription && (
              <TableRow>
                <TableCell>Estate Planning Expert-Guided</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>$39.99</TableCell>
              </TableRow>
            )}
            
            {!!post_death_subscription && (
              <TableRow>
                <TableCell>Estate Settlement Expert-Guided</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>$39.99</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/plan")}
        >
          Update Subscription
        </Button>
      </Box>
    </Box>
  );
};

export default ProfilePlan;
