import React from "react";
import { Box, Typography } from "@mui/material";
import IconUpload from "../../Assets/Images/icon_upload.svg";

const FileUploader = ({
  fileList,
  onFile,
  height = '200px',
  width = '100%',
  borderRadius = '10px',
  accept = 'image/*'
}) => {

  return (
    <Box
      sx={{ border: '1px dashed #4b6eae', minHeight: height, width }}
      bgcolor="#fff"
      borderRadius={borderRadius}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Box mb={1.5} display="flex" justifyContent="center">
        <img src={IconUpload} alt="Upload" />
      </Box>

      <Box mb={1.5}>
        <Typography color="#000" align="center">
          Drag & Drop
        </Typography>

        <Box display="flex" justifyContent="center">
          <Typography color="#000" component="span" sx={{ mr: 1 }}>or</Typography>
          <Typography color="primary.light">Browse</Typography>
        </Box>
      </Box>

      {fileList[0]?.name && (
        <Box mb={1.5}>
          <Box sx={{ borderRadius: 2.5 }} px={2} py={0.5} bgcolor="#ccc">{fileList[0]?.name}</Box>
        </Box>
      )}

      <Typography color="grey.500" align="center" variant="caption">
        JPEG, JPG, PNG, PDF, DOC
      </Typography>

      <input
        type="file"
        style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, opacity: 0 }}
        onChange={onFile}
        accept={accept}
      />
    </Box>
  );
};

export default FileUploader;
