import React from "react";
import { Box } from "@mui/material";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import FormContainer from "../../Components/Form/FormContainer";
import Input from "../../Components/Form/Input";

const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&~/`£])[A-Za-z\d@$!%*#?&~/`£]{8,}$/

const ChangePassword = ({ loading, handleSubmit }) => {
  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .trim()
      .required("Old Password is required")
      .matches(
        passwordRegEx,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    newPassword: Yup.string()
      .trim()
      .required("New Password is required")
      .matches(
        passwordRegEx,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    confirmPassword: Yup.string().when("newPassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("newPassword")], "New Password and Confirm Password should be same"),
    }),
  });

  return (
    <Box py={3}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#000' }}>
          Change Password
        </Typography>
      </Box>

      <FormContainer
        defaultValues={initialValues}
        validation={validationSchema}
        onSuccess={handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Input
              fullWidth
              name="oldPassword"
              type="password"
              label="Password"
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Input
              fullWidth
              name="newPassword"
              type="password"
              label="New Password"
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Input
              fullWidth
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              variant="filled"
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              color="primary"
              loading={loading}
              variant="contained"
              type="submit"
            >
              Update Password
            </LoadingButton>
          </Grid>
        </Grid>
      </FormContainer>
    </Box>
  );
};

export default ChangePassword;
